import { SensorType } from "../../../types";

export const selectImageForSensorType = (type: SensorType) => {
    switch (type) {
        case SensorType.cadence:
            return '/static/images/sensor_types/ANT.CAD.icon.bike.FA.jpg';
        case SensorType.power:
            return '/static/images/sensor_types/ANT.PWR.icon.bike.FA.jpg';
        case SensorType.speed:
            return '/static/images/sensor_types/ANT.SPD.icon.bike.FA.jpg';
        case SensorType.heartrate:
            return '/static/images/sensor_types/ANT.HR.icon.FA.jpg';
        default:
            return '/static/images/sensor_types/ANT.jpg';
    }
}

export const getSensorTypeByValue = (value: string) => {
    const indexOfS = Object.values(SensorType).indexOf(value as unknown as SensorType);
  
    const key = Object.keys(SensorType)[indexOfS];
  
    return key;
  }