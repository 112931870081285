import { createSlice } from "@reduxjs/toolkit";
import { User, UserAuthentication } from "../types";

interface AuthState extends UserAuthentication {
 user: User | null,
}

/*
{
    "access_token":"...",
    "refresh_token":"...",
    "token_type":"bearer",
    "access_token_issued":"2023-02-03T08:39:36+00:00",
    "refresh_token_issued":"2023-02-03T08:39:36+00:00",
    "access_token_expires":"2073-01-21T08:39:36+00:00",
    "refresh_token_expires":"2123-01-10T08:39:36+00:00"
}
*/
const initialState: AuthState = {
    access_token: localStorage.getItem('ACCESS_TOKEN') || '',
    refresh_token: localStorage.getItem('REFRESH_TOKEN') || '',
    token_type: localStorage.getItem('TOKEN_TYPE') || '',
    access_token_issued: localStorage.getItem('ACCESS_TOKEN_ISSUED') ? new Date(localStorage.getItem('ACCESS_TOKEN_ISSUED')!).toISOString() : null,
    refresh_token_issued: localStorage.getItem('REFRESH_TOKEN_ISSUED') ? new Date(localStorage.getItem('REFRESH_TOKEN_ISSUED')!).toISOString() : null,
    access_token_expires: localStorage.getItem('ACCESS_TOKEN_EXPIRES') ? new Date(localStorage.getItem('ACCESS_TOKEN_EXPIRES')!).toISOString() : null,
    refresh_token_expires: localStorage.getItem('REFRESH_TOKEN_EXPIRES') ? new Date(localStorage.getItem('REFRESH_TOKEN_EXPIRES')!).toISOString() : null,
    user: localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')!) : null,
};

export const authSlice = createSlice({
    initialState,    
    name: 'auth',
    reducers: {
        logout: () => ({
            access_token: '',
            refresh_token: '',
            token_type: '',
            access_token_issued: null,
            refresh_token_issued: null,
            access_token_expires: null,
            refresh_token_expires: null,
            user: null,
        }),
        login: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        setUser: (state, action) => ({
            ...state,
            user: action.payload,
        }),
    }
})

export default authSlice.reducer;