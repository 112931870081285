import React, { useEffect, useState } from "react";
import { DialogTitle, DialogContent, DialogContentText, TextField, Grid, Select, MenuItem } from "@mui/material";
import FormDialog from "../shared/FormDialog";
import { EventPoleLinkCreate } from "../../types";
import { useAddPoleToEventMutation, useUpdatePoleFromEventMutation, useGetPolesQuery } from "../../features/api/eventSlice";

interface FormEventAddEditPoleProps {
    eventId: number,
    linkedPoles: string[],
    editFormState?: [number, EventPoleLinkCreate],
    clearCallback?: () => void,
}

const FormEventAddEditPole = ({ eventId, linkedPoles, editFormState, clearCallback }: FormEventAddEditPoleProps) => {
    const initialState = {
        event_id: eventId,
        pole_id: '',

        pole_location_latitude: 0,
        pole_location_longitude: 0,

        t_grouping: 5,
        t_before: 0,
        t_after: 0,
        t_duration_min: 2,
        t_duration_max: 10,

        recording_metadata: '',
    };

    const [formEventPole, setFormEventPole] = useState<EventPoleLinkCreate>(initialState);
    const [addNewPole, { isLoading }] = useAddPoleToEventMutation();
    const [updatePole] = useUpdatePoleFromEventMutation();
    const { data: poles } = useGetPolesQuery();

    useEffect(() => {
        if (editFormState) {
            setFormEventPole(editFormState[1]);
        } else {
            setFormEventPole(initialState);
        }
    }, [editFormState]);

    const filteredPoles = poles?.filter(pole => !linkedPoles.includes(pole.id));
    const canSave = Object.values(formEventPole).every(x => x !== '') && !isLoading;

    const changeFormValue = (name: string, value: string) => setFormEventPole({
        ...formEventPole,
        [name]: value,
    });

    const onEventPoleFormSubmit = async () => {
        try {
            if (canSave) {
                if (editFormState) {
                    await updatePole(formEventPole);
                } else {
                    await addNewPole(formEventPole);
                }
                setFormEventPole(initialState);
            }
        } catch (err) {
            console.error('Failed to add new pole: ', err);
        }
    }

    return (
        <FormDialog 
            handleSubmit={onEventPoleFormSubmit}
            disableSubmit={!canSave}
            openButtonTitle='Add pole' 
            isOpenForEdit={editFormState !== undefined}
            closeCallback={clearCallback}
        >
            <DialogTitle>Add STRADA pole to event</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To subscribe to this website, please enter your email address here. We
                    will send updates occasionally.
                </DialogContentText>
                { editFormState ? 
                    <TextField
                        label='Pole identifier'
                        name='pole_id'
                        value={formEventPole.pole_id}
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="outlined"
                        disabled
                    />
                :
                    <Select
                        label='Pole identifier'
                        name='pole_id'
                        value={formEventPole.pole_id}
                        onChange={(e) => changeFormValue(e.target.name, e.target.value)}
                        fullWidth
                        margin='dense'
                        type='text'
                        variant='outlined'
                    >
                        {filteredPoles && filteredPoles.map((pole, i) => (
                            <MenuItem key={i} value={pole.id}>{pole.id}</MenuItem>
                        ))}
                    </Select>
                }
                <TextField
                    label='Description'
                    name='recording_metadata'
                    onChange={(e) => changeFormValue(e.target.name, e.target.value)}
                    value={formEventPole.recording_metadata}
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    label='Latitude'
                    name='pole_location_latitude'
                    onChange={(e) => changeFormValue(e.target.name, e.target.value)}
                    value={formEventPole.pole_location_latitude}
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    label='Longitude'
                    name='pole_location_longitude'
                    onChange={(e) => changeFormValue(e.target.name, e.target.value)}
                    value={formEventPole.pole_location_longitude}
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="outlined"
                />
                <Grid container spacing={2}>
                    <Grid item lg={6}>
                        <TextField
                            label='Minimal duration'
                            name='t_duration_min'
                            onChange={(e) => changeFormValue(e.target.name, e.target.value)}
                            value={formEventPole.t_duration_min}
                            margin="dense"
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            label='Maximum duration'
                            name='t_duration_max'
                            onChange={(e) => changeFormValue(e.target.name, e.target.value)}
                            value={formEventPole.t_duration_max}
                            margin="dense"
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={4}>
                        <TextField
                            label='Grouping factor'
                            name='t_grouping'
                            onChange={(e) => changeFormValue(e.target.name, e.target.value)}
                            value={formEventPole.t_grouping}
                            margin="dense"
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <TextField
                            label='Time before'
                            name='t_before'
                            onChange={(e) => changeFormValue(e.target.name, e.target.value)}
                            value={formEventPole.t_before}
                            margin="dense"
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <TextField
                            label='Time after'
                            name='t_after'
                            onChange={(e) => changeFormValue(e.target.name, e.target.value)}
                            value={formEventPole.t_after}
                            margin="dense"
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </FormDialog>
    );
}

export default FormEventAddEditPole;