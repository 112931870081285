import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { UserDisplayComponent } from "../components/users/userDisplay";
import { RootState } from "../store";

export const MyProfilePage = () => {
    const userObj = useSelector((state: RootState) => state.authState.user);
    const accessToken = useSelector((state: RootState) => state.authState.access_token);
    const tokenType = useSelector((state: RootState) => state.authState.token_type);
    const accessTokenExpires = useSelector((state: RootState) => state.authState.access_token_expires);
    const accessTokenIssued = useSelector((state: RootState) => state.authState.access_token_issued);
    const refreshToken = useSelector((state: RootState) => state.authState.refresh_token);
    const refreshTokenExpires = useSelector((state: RootState) => state.authState.refresh_token_expires);
    const refreshTokenIssued = useSelector((state: RootState) => state.authState.refresh_token_issued);

    const datify = (t: string | null) => t ? new Date(t) : new Date();

    return (
        <Container>
            <Typography variant={"h5"}>User information</Typography>
            <Box component="div" sx={{ display: 'block', margin: '15px'}}>
                <UserDisplayComponent user={userObj}/>
            </Box>
            <Typography variant={"h5"}>Stats for nerds</Typography>
            <TokenCard accessToken={accessToken}
                        refreshToken={refreshToken}
                        tokenType={tokenType}
                        accessTokenExpires={datify(accessTokenExpires)}
                        accessTokenIssued={datify(accessTokenIssued)}
                        refreshTokenExpires={datify(refreshTokenExpires)}
                        refreshTokenIssued={datify(refreshTokenIssued)}
            />
        </Container>
    );    
};

interface TokenCardProps{
    accessToken: string|null,
    accessTokenIssued: Date|null,
    accessTokenExpires: Date|null,
    refreshToken: string|null,
    refreshTokenIssued: Date|null,
    refreshTokenExpires: Date|null,
    tokenType: string|null
}

function TokenCard(props: TokenCardProps) {
    return (
        <>
            <Box component="div" sx={{ display: 'block', margin: '15px' }}>
                <Card sx={{ minWidth: 275}}>
                    <CardContent>
                    <Typography sx={{ fontSize: 11, wordBreak: 'break-all'}} color="text.secondary" gutterBottom>
                        {props.accessToken}
                    </Typography>
                    <Typography variant="h5" component="div">
                        Access Token
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Expires at {props.accessTokenExpires?.toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                        <p>Issued at {props.accessTokenIssued?.toLocaleString()}</p>
                    </Typography>
                    </CardContent>
                </Card>
            </Box>
            <Box component="div" sx={{ display: 'block', margin: '15px' }}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                    <Typography sx={{ fontSize: 11, wordBreak: 'break-all'}} color="text.secondary" gutterBottom>
                        {props.refreshToken}
                    </Typography>
                    <Typography variant="h5" component="div">
                        Refresh Token
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Expires at {props.refreshTokenExpires?.toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                        <p>Issued at {props.refreshTokenIssued?.toLocaleString()}</p>
                    </Typography>
                    </CardContent>
                </Card>
            </Box>
        </>       
    );
  }

export default MyProfilePage;