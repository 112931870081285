import { Typography, Stack, Box } from '@mui/material';
import React from 'react';
import { SensorInputForm } from '../components/sensors/SensorInputForm';
import SensorOverview from '../components/sensors/SensorOverview';

const Settings = () => (
    <Stack spacing={2} className='userSettings'>
        <Box>
            <Typography variant="h5" component="h5">
                My sensors
            </Typography>
            <SensorOverview/>
        </Box>
        <Box>
            <Typography variant="h5" component="h5">
                Add a new sensor
            </Typography>
            <SensorInputForm/>
        </Box>
    </Stack>

);

export default Settings;