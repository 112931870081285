import { SensorMinimal, SensorCreate } from "../../types";
import { apiSlice } from "./apiSlice";

const sensorSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        meSensors: builder.query<SensorMinimal[], void>({
            query: () => ({
                url: '/users/me/sensors',
                credentials: 'include',
            }),
            providesTags: ['SensorTag']
        }),
        addMeSensor: builder.mutation<{}, SensorCreate>({
            query: sensor => ({
                url: `/users/me/add_sensor`,
                method: 'POST',
                body: sensor,
                headers: {
                'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['SensorTag','EventTag','MyEventTag']
        }),
        unlinkMeSensor: builder.mutation<void, string>({
            query: (sensorId) => ({
                url: `/users/me/sensor/${sensorId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['SensorTag']
        }),
    }),
});

export const {
  useMeSensorsQuery,
  useUnlinkMeSensorMutation,
  useAddMeSensorMutation,
} = sensorSlice;