export enum SensorType {
    heartrate = "hr",
    cadence = "cad",
    speed = "spd",
    power = "pwr",
    unknown = "unk"
}

export enum SensorManufacturer{
    GARMIN = "GAR",
    WAHOO = "WAH",
    SIGMA = "SIG",
    SRM = "SRM",
    HAMMERHEAD = "HMH",
    POLAR = "POL",
    GEONAUTE = "GEO",
    SUUNTO = "SUN",
    UNKNOWN = "UNK",
    TEST = "TST",
}

export interface SensorMinimal {
    id: number,
    device_id: string,
    manufacturer: SensorManufacturer,
    sensor_type: SensorType,
}

export interface SensorErrorMessage{
    message: string
}

export interface SensorCreate extends Omit<SensorMinimal, 'id'> {}
