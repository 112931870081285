import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import { apiSlice } from '../features/api/apiSlice';
import {authSlice} from '../features/authSlice';

const LogOut = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('REFRESH_TOKEN');
        localStorage.removeItem('user');
        localStorage.removeItem('TOKEN_TYPE');
        localStorage.removeItem('ACCESS_TOKEN_ISSUED');
        localStorage.removeItem('ACCESS_TOKEN_EXPIRES');
        localStorage.removeItem('REFRESH_TOKEN_ISSUED');
        localStorage.removeItem('REFRESH_TOKEN_EXPIRES');
        dispatch(authSlice.actions.logout());
        dispatch(apiSlice.util.resetApiState());
        navigate('/login');
    },[]);

    return <></>;
}

export default LogOut;