import { Avatar, Box, Button, Container, createTheme, CssBaseline, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, ThemeProvider } from "@mui/material"
import { useEffect, useState } from "react";
import SensorsIcon from '@mui/icons-material/Sensors';
import { SensorErrorMessage, SensorManufacturer, SensorType } from "../../types";
import { isFetchBaseQueryError } from "../../helpers/apiHelpers";
import { useAddMeSensorMutation } from "../../features/api/sensorSlice";

const theme = createTheme();

export const SensorInputForm = () => {
    const [addNewSensorDb, { error, isLoading }] = useAddMeSensorMutation();
    
    let [message, setMessage] = useState("");
    let [messageVisibility, setMessageVisibility] = useState("none");
    
    let [state, setState] = useState({
        "device_id": "",
        "sensor_type": SensorType.unknown,
        "manufacturer": SensorManufacturer.UNKNOWN
    });

    const displayMessage = async (text: string) => {
        setMessage(text);
        setMessageVisibility("block");
    }

    useEffect(()=>{
        if(error && isFetchBaseQueryError(error)){
            console.log("we have an error")
            console.log(error)
            if(error.data)
                displayMessage((error.data as SensorErrorMessage).message)
        }
    }, [error]);

    const addNewSensorToAccount = async () => {
        try{
            if(state.device_id!=''){
                await addNewSensorDb(state)
            }
            else{
                displayMessage("Sensor Identifier cannot be empty.")
            }
        }
        catch (err) {
            console.error('Failed to add new sensor to user: ', err);
        }
        finally{
           setState({
                "device_id": "",
                "sensor_type": SensorType.unknown,
                "manufacturer": SensorManufacturer.UNKNOWN
            })
        }
    }
    
    const handleChange = (evt:any) => {
        setState({
            ...state,
            [evt.target.name]: evt.target.value
        });
      };

    return (<div>
                <ThemeProvider theme={theme}>
                <Container component="main"  maxWidth="xs" sx={{display: 'block', alignItems: 'left', marginLeft: 0}}>
                    <CssBaseline />
                    <div className="message" style={{display:messageVisibility}}>
                        <span className="closebtn" onClick={()=>{setMessageVisibility('none')}}>&times;</span>
                        {message}
                    </div> 
                    <Box>
                        <Box component="form" onSubmit={addNewSensorToAccount} noValidate sx={{ mt: 1 }}>
                            <FormControl required fullWidth margin="normal">
                                <InputLabel htmlFor="device_id">
                                    Sensor identifier (ANT+ id)
                                </InputLabel>
                                <Input
                                    name="device_id"
                                    type="text"
                                    autoComplete="device_id"
                                    value={state.device_id}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl required fullWidth margin="normal">
                                <InputLabel>
                                    Sensor manufacturer
                                </InputLabel>
                                <Select
                                    name="manufacturer"
                                    autoComplete="manufacturer"
                                    value={state.manufacturer}
                                    onChange={handleChange}
                                    label="Sensor manufacturer"
                                >
                                {
                                    Object.keys(SensorManufacturer).map((key, index) => (
                                        <MenuItem
                                            key={Object.values(SensorManufacturer)[index]}
                                            value={Object.values(SensorManufacturer)[index]}
                                        >
                                            {key}
                                        </MenuItem>
                                    ))
                                }
                                </Select>
                            </FormControl>

                            <FormControl required fullWidth>
                            <InputLabel id="sensor_type_label">
                                Sensor type
                            </InputLabel>
                            <Select
                                    name="sensor_type"
                                    autoComplete="sensor_type"
                                    value={state.sensor_type}
                                    labelId="sensor_type_label"
                                    label="Sensor type"
                                    onChange={handleChange}
                                >
                                {
                                    Object.keys(SensorType).map((key, index) => (
                                        <MenuItem
                                            key={Object.values(SensorType)[index]}
                                            value={Object.values(SensorType)[index]}
                                        >
                                            {key}
                                        </MenuItem>
                                    ))
                                }
                                </Select>
                            </FormControl>
                            <Button
                                disableRipple
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={addNewSensorToAccount}
                            >
                            Add
                            </Button>
                        </Box>
                    </Box>
                </Container>
                </ThemeProvider>


    </div>)
}