import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, CardMedia, CircularProgress, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { SensorMinimal, SensorType } from '../../types';
import { getSensorTypeByValue, selectImageForSensorType,  } from './helpers';
import FormDialog from '../shared/FormDialog';
import { useUnlinkMeSensorMutation, useMeSensorsQuery } from '../../features/api/sensorSlice';

interface SensorExcerptProps {
    sensor: SensorMinimal,
}

const SensorOverviewContainer = styled('div')(() => ({
}));

const StyledGrid = styled(Grid)(() => ({
  marginTop: '10px',
}));

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
    color: 'inherit',
}));

const SensorExcerpt = ({ sensor }: SensorExcerptProps) => {
    const [unlinkSensor, {}] = useUnlinkMeSensorMutation();

    return (
      <Grid item key={sensor.id} lg={2}>
        <Card sx={{ maxWidth: 345 }} >
          <CardMedia
            sx={{ height: 50, width: 50 }}
            image={selectImageForSensorType(sensor.sensor_type)}
            style={{ justifyContent: "center", display: "flex" }}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {getSensorTypeByValue(sensor.sensor_type)}
            </Typography>
            {sensor.device_id}
          </CardContent>
          <CardActions>
            <Button size="small">
              <FormDialog handleSubmit={()=>unlinkSensor(sensor.device_id)}
                          openButtonTitle='Delete sensor' 
                          submitMessage='Delete'
                          closeCallback={()=>{}}>
                <DialogTitle>Are you sure you want to delete this sensor from your account.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You wont be able to view the events we detected your sensor identifier in anymore.
                    </DialogContentText>
                </DialogContent>
              </FormDialog>
            </Button>
          </CardActions>
        </Card>
      </Grid>
    )
}

const SensorOverview = () => {
    const [filterState, setFilterState] = useState<string[]>([]);
    const [filteredSensors, setFilteredSensors] = useState<SensorMinimal[]>([]);
    const {
        data: sensors,
        isLoading,
        isSuccess,
        isError,
        error
    } = useMeSensorsQuery();

    useEffect(() => {
      if (sensors) {
        const filtered = filterState.length == 0 ? sensors : sensors.filter(x => filterState.includes(x.sensor_type));
        setFilteredSensors(filtered);
      }
    }, [filterState, sensors]);

    const handleFilterState = (sensor: React.MouseEvent<HTMLElement>, newFilters: string[]) => {
      setFilterState(newFilters);
    }

    let content;
    if (isLoading) {
        return (<CircularProgress />)
    } else if (isSuccess) {
        content = filteredSensors.map(sensor => <SensorExcerpt key={sensor.id} sensor={sensor} />);
    }

    return (
      <SensorOverviewContainer>
        <ToggleButtonGroup
          value={filterState}
          onChange={handleFilterState}
        >
          {Object.values(SensorType).map((type, index) => (
            <ToggleButton key={index} value={type} aria-label={type}>
              {type}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <StyledGrid container spacing={2}>
          {content}    
        </StyledGrid>
      </SensorOverviewContainer>
    );
}

export default SensorOverview;