import { EventPoleLink } from "./pole";

export enum EventType {
    CYCLING = 'cycling',
    RUNNING = 'running',
    MULTISPORT = 'multi-sport',
    WALKING = 'walking',
    SKIING = 'skiing',
    OTHER = 'other',
}

export interface EventMinimal {
    id: number,
    title: string,
    description: string,
    start_time: Date,
    end_time: Date,
    type: EventType,
}

export interface Event extends EventMinimal {
    devices: string[],
    poles: EventPoleLink[],
}