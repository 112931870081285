import { User, UserAuthentication, UserRegistrationResponse, UserRegistrationData } from "../../types";
import { authSlice } from "../authSlice";
import { apiSlice } from "./apiSlice";

const authenticationSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMe: builder.query<User, null>({
            query: () => ({
                url: '/users/me',
                credentials: 'include',
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                const { data } = await queryFulfilled;
                localStorage.setItem('user', JSON.stringify(data))
                dispatch(authSlice.actions.setUser(data));
                } catch (error) { console.log(error) }
            },
            providesTags: ['LoginTag'],
        }),
        login: builder.mutation<UserAuthentication, FormData>({ // Fast-api requires login data to be of type FormData
            query: payload => ({
                url: '/token',
                method: 'POST',
                body: payload,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                dispatch(authSlice.actions.setUser(null));
                const { data } = await queryFulfilled;
                dispatch(authSlice.actions.login(data)); // Save tokens to store

                localStorage.setItem('ACCESS_TOKEN', data.access_token);
                localStorage.setItem('REFRESH_TOKEN', data.refresh_token);
                localStorage.setItem('TOKEN_TYPE', data.token_type);

                localStorage.setItem('ACCESS_TOKEN_ISSUED', data.access_token_issued ? new Date(data.access_token_issued).toString() : '');
                localStorage.setItem('ACCESS_TOKEN_EXPIRES', data.access_token_expires ? new Date(data.access_token_expires).toISOString() : '');
                localStorage.setItem('REFRESH_TOKEN_ISSUED', data.refresh_token_issued ? new Date(data.refresh_token_issued).toISOString() : '');
                localStorage.setItem('REFRESH_TOKEN_EXPIRES', data.refresh_token_expires ? new Date(data.refresh_token_expires).toISOString() : '');

                } catch (error) { console.log('test', error) }
            },
            invalidatesTags: ['LoginTag', 'EventTag', 'MyEventTag', 'SensorTag'],
            }),
        // Registration
        register: builder.mutation<UserRegistrationResponse, UserRegistrationData>({
            query: payload => ({
                url: '/users/register',
                method: 'POST',
                body: payload
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                const { data } = await queryFulfilled;
                dispatch(authSlice.actions.login(data)); // Save tokens to store

                localStorage.setItem('ACCESS_TOKEN', data.access_token);
                localStorage.setItem('REFRESH_TOKEN', data.refresh_token);
                localStorage.setItem('TOKEN_TYPE', data.token_type);

                localStorage.setItem('ACCESS_TOKEN_ISSUED', data.access_token_issued ? new Date(data.access_token_issued).toISOString() : '');
                localStorage.setItem('ACCESS_TOKEN_EXPIRES', data.access_token_expires ? new Date(data.access_token_expires).toISOString() : '');
                localStorage.setItem('REFRESH_TOKEN_ISSUED', data.refresh_token_issued ? new Date(data.refresh_token_issued).toISOString() : '');
                localStorage.setItem('REFRESH_TOKEN_EXPIRES', data.refresh_token_expires ? new Date(data.refresh_token_expires).toISOString() : '');

                } catch (error) { console.log(error) }
            },
            invalidatesTags: ['LoginTag'],
        }),
    }),
});

export const {
  useGetMeQuery,
  useLoginMutation,
  useRegisterMutation,
} = authenticationSlice;