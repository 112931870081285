import { EventType } from "../../../types";

export * from './dates';

export const selectImageForEventType = (type: EventType) => {
    switch (type) {
        case EventType.CYCLING:
        case EventType.RUNNING:
        case EventType.MULTISPORT:
        case EventType.SKIING:
        case EventType.WALKING:
        case EventType.OTHER:
        default:
            return '/static/images/default_card/cycling.jpg';
    }
}