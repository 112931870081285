export const validateEmail = (email: string) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    console.log(email);
        
    if(email.match(emailRegex)){
        return true;
    }
    else{
        return false;
    }
}

function containsNumbers(str:string) {
    return /\d/.test(str);
}

function containsUppercase(str: string) {
    return /[A-Z]/.test(str);
}

function containsLowercase(str: string) {
    return /[a-z]/.test(str);
}

function containsSpecialChars(str: string){
    var format = /[$@#%&!]/;
    return format.test(str);
}
    

export const validatePassword = (password: string) => {
    var validation_errors = []
     
    if(password.length < 6){
        validation_errors.push('length should be at least 6');
    }
    if(password.length > 20){
        validation_errors.push('length should be not be greater than 8')
    }
    if(!containsNumbers(password)){
        validation_errors.push('Password should have at least one numeral')
    }         
    if(!containsUppercase(password)){
        validation_errors.push('Password should have at least one uppercase letter')
    }  
    if(!containsLowercase(password)){
        validation_errors.push('Password should have at least one lowercase letter')
    }       
    if(!containsSpecialChars(password)){
        validation_errors.push('Password should have at least one of the symbols $@#&!')
    }
    
    return validation_errors;
}