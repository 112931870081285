import { DeleteForeverOutlined } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridCellParams, GridColDef, GridRowParams, MuiEvent } from "@mui/x-data-grid";
import React from "react";
import { ClipInfoWithPole } from "../../types";
import { clipsDataGrid } from "../events/helpers/dataGridDefinitions";

interface DevicesListProps {
    clips: ClipInfoWithPole[],
    selectedRow: number,
    handleDeleteClip: CallableFunction,
    handleSelectClip: CallableFunction,
}


const ClipsList = ({ clips, selectedRow, handleDeleteClip, handleSelectClip }: DevicesListProps) => {

    const onSelectClip = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        handleSelectClip(params.id);
    }

    const clipsDataGridColumns: GridColDef[] = [
        ...clipsDataGrid,
        {
            field: 'actions',
            headerName: '',
            width: 300,
            renderCell: ({ row }: GridCellParams) => {
                const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
                    handleDeleteClip(row.id);
                }

                return (
                    <Tooltip title='Delete clips'>
                        <Button onClick={handleOnClick}><DeleteForeverOutlined /></Button>
                    </Tooltip>
                );
            }
        },
    ];
    return (
        <DataGrid
            columns={clipsDataGridColumns}
            rows={clips.map((c, i) => ({ 
                id: i,
                description: c.description, 
                timestamp: new Date(Date.parse(c.start_datetime)).toLocaleTimeString('nl-BE'),
            }))}
            pageSize={5}
            rowsPerPageOptions={[5]}
            onRowClick={onSelectClip}
            selectionModel={[selectedRow]}
            autoHeight
        />
    )
}

export default ClipsList;