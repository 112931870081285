import { Button, Dialog, DialogActions } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface FormDialogProps {
    children: React.ReactNode,
    handleSubmit: () => void,
    disableSubmit?: boolean,
    openButtonTitle: string,
    submitMessage?: string,
    isOpenForEdit?: boolean,
    closeCallback?: () => void,
}

const FormDialog = ({ children, handleSubmit, disableSubmit = false, openButtonTitle, isOpenForEdit = false, submitMessage, closeCallback }: FormDialogProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
      setOpen(false);
      if (closeCallback) {
        closeCallback();
      }
    };

    useEffect(() => {
      if (!isOpenForEdit) {
        handleClose();
      } else {
        handleClickOpen();
      }
    }, [isOpenForEdit]);

      return (
        <>
          <Button variant="outlined" onClick={handleClickOpen}>
            {openButtonTitle}
          </Button>
          <Dialog open={open} onClose={handleClose}>
                {children}
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={disableSubmit} onClick={() => {
                        handleSubmit();
                        handleClose();
                    }}>{submitMessage?submitMessage:"Submit"}</Button>
                </DialogActions>
          </Dialog>
        </>
      );
}

export default FormDialog;