import React from 'react';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, styled, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import SettingsIconMenu from '../settings/SettingsIconMenu';
import { HierarchicalRoleChecker } from '../../helpers/AuthValidators';

const pages = [
    {
        name: 'Home', link: '/',
    },
    {
        name: 'My Events', link: '/events/me', minimumPrivilege: 'athlete'
    },
    {
        name: 'Dashboard', link: '/dashboard', minimumPrivilege: 'admin'
    }
];

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
    color: 'inherit',
}));

const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const isLoggedIn = useSelector((state: RootState) => state.authState.user !== null);
    const highestPrivilege = useSelector((state: RootState) => state.authState.user !== null ? state.authState.user.highest_role : null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    
    return (
        <AppBar position="static">
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    {/*<AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                    STRADA
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page, i) => (
                                <MenuItem key={i}>
                                    <StyledLink to={ page.link}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </StyledLink>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    {/*<AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />*/}
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                    STRADA
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page, i) => {
                            if(page.minimumPrivilege){
                                if(highestPrivilege){
                                    var roleChecker = new HierarchicalRoleChecker(highestPrivilege);
                                    if(!roleChecker.hasPrivilege(page.minimumPrivilege)){
                                        return ;
                                    }
                                }
                                else{
                                    return;
                                }
                            }
                            return (<Button
                                key={i}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {
                                    <StyledLink to={page.link}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </StyledLink>
                                    }
                            </Button>)
                        }
                        )}
                    </Box>
                    {isLoggedIn
                    ? 
                        <SettingsIconMenu handleCloseUserMenu={handleCloseUserMenu} handleOpenUserMenu={handleOpenUserMenu} anchorElUser={anchorElUser}/>
                    : 
                        <Box sx={{display:'flex', flexDirection: 'row', columnGap: 1}}>
                            <Button variant='outlined' sx={{color: 'white', border: 1}}>
                                <StyledLink to='login'>
                                    <Typography textAlign="center">Log in</Typography>
                                </StyledLink>
                            </Button>
                            <Button variant='outlined' sx={{color: 'white', border: 1}}>
                                <StyledLink to='register'>
                                    <Typography textAlign="center">Register</Typography>
                                </StyledLink>
                            </Button>
                        </Box>
                    }
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;