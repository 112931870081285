import { Container, styled } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Header, Footer } from '../components/shared';

interface LayoutProps {
    children: React.ReactNode
}

const StyledDiv = styled('div')(() => ({
   display: 'flex',
   minHeight: '100vh',
   flexDirection: 'column', 
}));

const Layout = ({ children }: LayoutProps) => {
    const { pathname } = useLocation();

    return (
        <>
            <StyledDiv>
                {/*{pathname !== '/login' ? <Header /> : null}*/}
                <Header/>
                <Container maxWidth='lg' sx={{ marginTop: '10px' }}>
                    {children}
                </Container>
            </StyledDiv>
            <Footer />
        </>
    )
}

export default Layout;