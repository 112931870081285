import { AddCircle } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';


const Home = () => {  
    return (
        <Grid container spacing={6}>
            <Grid item xs={12} md={7}>
                <Typography variant="h4">
                    What is STRADA?
                </Typography>
                <Typography variant="h6">
                    Sensor-Driven Tripod for Recording Athlete Data.
                </Typography>
                <Typography variant="caption" sx={{ textAlign:'justify', textJustify: 'inter-word'}}>
                    The aim of the StarTT project STRADA is to develop a tool that collects, analyses and learns from sports data. We aim to capture available data streams, such as heart rate monitors, video streams, and lap times in real-time and use all these data streams to automatically detect highlights during training or competition, e.g., an attack of your favorite rider in track cycling, an overtake in short track speed skating, …

                    The framework generates short, personalized video clips that might attract the broader attention of sports fans and broadcasting companies. STRADA is our answer to keep sports fans engaged in a time where on-demand information has become mainstream, and viewers are often saturated with an abundance of long live race coverage. Moreover, these filtered video clips also have value for athletes and coaches to improve technical and tactical skills.
                </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
                <Box
                    component='img'
                    sx={{
                        width: '100%'
                    }}
                    alt='STRADA in the wild'
                    src='static/images/stradainthewild.jpg'   
                />
            </Grid>
            <Grid item xs={12} lg={12}>
            <iframe 
                width="100%" 
                height="720"
                src="https://www.youtube.com/embed/1s3kt71ZtpQ"
                title="STRADA results LIERDE" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen
            />
            </Grid>
            <Grid item xs={12} lg={12}>
                <Box 
                    component='img'
                    sx={{
                        width: '100%'
                    }}
                    alt='Strada flyer'
                    src='static/images/STRADA_Flyer_02-15.svg'
                />
            </Grid>
        </Grid>
    )
}

export default Home;