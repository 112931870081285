import { Grid, Typography, Box, Snackbar, AlertColor, Alert, Button, Select, MenuItem, Tooltip, SelectChangeEvent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetCeleryStatusQuery } from "../../features/api/clipSlice";
import { useGetClipgenStatusEndpointQuery, useGetEventClipsForDeviceQuery } from "../../features/api/eventSlice";
import VideoPlayer from "./VideoPlayer";
import { OndemandVideoOutlined } from "@mui/icons-material";
import { EventPoleLink } from "../../types";
import ClipsList from "./ClipsList";

interface VideoOverviewProps {
    eventId: number,
    devices: string[],
    show: boolean,
    poles: EventPoleLink[],
}

const VideoOverview = ({ eventId, devices, show, poles }: VideoOverviewProps) => {
    const [clickedDeviceId, setClickedDeviceId] = useState<string>();
    const [clipgenStatusId, setClipgenStatusId] = useState<string>('');
    const [clipgenSnackbar, setClipgenSnackbar] = useState({ open: false, severity: 'success', msg: '' });

    const [autoplay, setAutoplay] = useState(true);

    const [selectedDevice, setSelectedDevice] = useState('');
    const [selectedClipIdx, setSelectedClipIdx] = useState(0);

    const { data: clipsData, isLoading: isLoadingClips, isSuccess: isSuccessClips, refetch: refetchClips } = useGetEventClipsForDeviceQuery({
        eventId: Number(eventId),
        deviceId: selectedDevice,
    }, { skip: Boolean(selectedDevice === '') });

    const { data: clipgenData, isLoading: isLoadingClipgen, isSuccess: isSuccessClipgen } = useGetClipgenStatusEndpointQuery({
        eventId: Number(eventId),
        deviceId: clickedDeviceId || '',
    }, { skip: !clickedDeviceId });

    const { data: clipgenStatus, isLoading: isLoadingClipgenStatus, isSuccess: isSuccessClipgenStatus } = useGetCeleryStatusQuery(
        clipgenStatusId,
        {
            skip: clipgenStatusId === '',
            pollingInterval: 1000,
        }
    );

    useEffect(() => {
        if (show && devices.length > 0) {
            setSelectedDevice(String(devices[0]));
        }
    }, [show])

    useEffect(() => {
        if (!isLoadingClipgen && isSuccessClipgen) {
            setClipgenStatusId(clipgenData);
        }
    }, [isLoadingClipgen, isSuccessClipgen]);

    useEffect(() => {
        if (!isLoadingClipgenStatus && isSuccessClipgenStatus) {
            switch (clipgenStatus.state) {
                case 'SUCCESS':
                    refetchClips();
                    setClipgenStatusId('');
                    setClickedDeviceId(undefined);
                    setClipgenSnackbar({
                        open: true,
                        severity: 'success',
                        msg: 'Successully generated clips!',
                    })
                    break;
                case 'FAILURE':
                    setClipgenStatusId('');
                    setClickedDeviceId(undefined);
                    setClipgenSnackbar({
                        open: true,
                        severity: 'error',
                        msg: 'Clip generation failed!',
                    })
                    break;
                case 'PROGRESS':
                    setClipgenSnackbar({
                        open: true,
                        severity: 'info',
                        msg: 'Generating clips...',
                    });
                    break;
                case 'PENDING':
                    setClipgenSnackbar({
                        open: true,
                        severity: 'warning',
                        msg: 'Clips request queued',
                    });
                    break;
            }
        }
    }, [isLoadingClipgenStatus, isSuccessClipgenStatus, clipgenStatus]);

    const handleSnackbarClose = (event?: React.BaseSyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setClipgenSnackbar(prev => ({ ...prev, open: false, }));
    } 

    const handleSelectDevice = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSelectedDevice(e.target.value as string);
    }

    const changeClip = (increment: boolean) => {
        if (!isLoadingClips && isSuccessClips) {
            setSelectedClipIdx(prevIdx => {
                const incrementedIdx = (increment ? prevIdx + 1 : prevIdx - 1); 

                // Modulo that support negative numbers.
                const m = clipsData.length;
                const idxMod = ((incrementedIdx % m) + m) % m;

                return idxMod;
            });
        }
    }

    const handleVideoFinished = () => {
        if (autoplay) {
            changeClip(true);
        }
    }

    return (
    <>
        <Snackbar open={clipgenSnackbar.open} onClose={handleSnackbarClose} autoHideDuration={clipgenSnackbar.severity === 'success' ? 5000 : null}>
            <Alert onClose={handleSnackbarClose} severity={clipgenSnackbar.severity as AlertColor}>
                {clipgenSnackbar.msg}
            </Alert>
        </Snackbar>
        {(!isLoadingClips && isSuccessClips) && (
            <>
                <Grid item xs={12} lg={12}>
                    <Typography variant='h4'>{`Streaming clips for ${selectedDevice}`}</Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    {clipsData.length > 0 && selectedClipIdx <= clipsData.length 
                        ? <VideoPlayer eventId={Number(eventId!)} deviceId={selectedDevice} clipId={clipsData[selectedClipIdx].name} handleVideoEnded={handleVideoFinished} />
                        : <img src='/static/images/videounavailable.webp' width={ '100%' }/>
                    }
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Box sx={{ height: '100%', minHeight: 300 }}>
                        <div style={{ display: 'flex', marginBottom: 5}}>
                            <TextField
                                id='selectDeviceId' 
                                label="Selected sensor"
                                variant='outlined'
                                onChange={handleSelectDevice}
                                value={selectedDevice}
                                select
                                sx={{ flexGrow: 5 }}
                            >
                                {devices.map((device, index) => (
                                    <MenuItem key={index} value={device}>
                                        {device}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Tooltip title='Generate clips' sx={{ flexGrow: 1 }}>
                                <Button onClick={() => setClickedDeviceId(selectedDevice)}><OndemandVideoOutlined /></Button>
                            </Tooltip>
                        </div>
                        <ClipsList 
                            clips={clipsData.map(c => ({
                                ...c,
                                description: poles.find(p => p.id === c.event_pole_id)?.recording_metadata || '',
                            }))}
                            selectedRow={selectedClipIdx}
                            handleDeleteClip={() => {}}
                            handleSelectClip={setSelectedClipIdx}
                        />
                    </Box>
                </Grid>
            </>
        )}
    </>);
}

export default VideoOverview;