import { ClipgenStatus } from "../../types/clip";
import { apiSlice } from "./apiSlice";

const clipSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCeleryStatus: builder.query<ClipgenStatus, string>({
            query: taskId => `/events/status/${taskId}`,
        }),
    }),
});

export const {
    useGetCeleryStatusQuery,
} = clipSlice;