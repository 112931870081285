import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Dashboard, Home, Login, LogOut, Register, Settings, MyProfilePage} from '../pages';
import EventDetail from '../components/events/EventDetail';
import GuardedElement from '../helpers/AuthValidators';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import EventOverview from '../components/events/EventOverview';

const AppRoutes = () => (
    <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/dashboard' element={<GuardedElement requiredPrivilege={'admin'}><Dashboard/></GuardedElement>}/>
        <Route path='events'>
            <Route path=':id' element={<GuardedElement requiredPrivilege={'admin'}><EventDetail overview /></GuardedElement>} />
            <Route path='me' element={<GuardedElement requiredPrivilege={'athlete'}><EventOverview myEvents /></GuardedElement>}/>
            <Route path='me/:id' element={<GuardedElement requiredPrivilege={'athlete'}><EventDetail overview={false} /></GuardedElement>}/>
        </Route>
        <Route path='/login' element={<Login />}/>
        <Route path='/register' element={<GoogleReCaptchaProvider reCaptchaKey="6LdZCi8kAAAAAKtyVQZpceP4gYFAZD2QdkaK2-ak"><Register /></GoogleReCaptchaProvider>}/>
        <Route path='/logout' element={<GuardedElement><LogOut/></GuardedElement>}/>
        <Route path='users'>
            <Route path=':id'/>
            <Route path='me' element={<GuardedElement><MyProfilePage/></GuardedElement>}/>
        </Route>
        <Route path='settings' element={<Settings />} />
    </Routes>
);

export default AppRoutes;