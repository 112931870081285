import React from 'react';
import { useSelector } from 'react-redux';
import { Route, redirect, RouteProps, PathRouteProps, Navigate, useLocation } from 'react-router-dom';
import { RootState } from '../store';
import { User } from '../types';

export interface GuardedRouteProps extends PathRouteProps{
    requiredPrivilege: string|null
}

const GuardedElement = ({ children, requiredPrivilege } : {children: JSX.Element, requiredPrivilege?: string}) => {
    let location = useLocation();
    var userObj = useSelector((state: RootState) => state.authState.user);
    if(userObj){
        const roleCheckerObj = new HierarchicalRoleChecker(userObj.highest_role);
        if(requiredPrivilege !== null && !roleCheckerObj.hasPrivilege(requiredPrivilege!)){
            return <Navigate to="/login" state={{ from: location, message: "Insufficient privileges." }} />;        
        }
    }
    else {
        return <Navigate to="/login" state={{ from: location, message: "You are not logged in." }} />;        
    }
    return children;
}

export class HierarchicalRoleChecker {
    static hierarchy = ['athlete', 'coach', 'staff', 'admin'];
    userPrivilege = 'athlete';

    constructor(userPrivilege: string) {
        this.userPrivilege = userPrivilege;        
    }

    hasPrivilege(requiredPrivilege: string){
        var requiredIndex = HierarchicalRoleChecker.hierarchy.indexOf(requiredPrivilege)
        var userPrivilegeIndex = HierarchicalRoleChecker.hierarchy.indexOf(this.userPrivilege);

        return requiredIndex<=userPrivilegeIndex;
    }
}

export const CheckIfAdmin = (userObj: User|null) => {
    if(userObj){
        const roleCheckerObj = new HierarchicalRoleChecker(userObj.highest_role);
        return roleCheckerObj.hasPrivilege('admin')
    }
    return false;
}

export default GuardedElement;