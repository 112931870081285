import { ReactEventHandler } from 'react';

interface VideoPlayerProps {
    eventId: number,
    deviceId: string | undefined,
    clipId: string,
    handleVideoEnded: ReactEventHandler<HTMLVideoElement>,
}

const VideoPlayer = ({ eventId, deviceId, clipId, handleVideoEnded }: VideoPlayerProps) => {
    if (!deviceId) {
        return null;
    }

    return (
        <>
            {/* Use this if multiple sources for single video: https://stackoverflow.com/a/32215374 */}
            <video controls preload='buffer' 
                onEnded={handleVideoEnded}
                style={{ width: '100%', maxHeight: '400px' }}
                autoPlay
                src={`https://strada.sportsdatascience.be:8090/clips/watch?event_id=${eventId}&device_id=${deviceId}&clip_id=${clipId}`} 
            />
        </>
    );
}

export default VideoPlayer;