import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { RootState } from '../../store';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://strada.sportsdatascience.be:8090',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).authState.access_token;

    if (token) {
      // If token is set in state, assume that it needs to be sent with the requests.
      headers.set('authorization', `Bearer ${token}`);
    }
  }
})

export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: ['EventTag', 'MyEventTag', 'LoginTag', 'SensorTag', 'ClipsTag'],
  endpoints: _ => ({}),
});