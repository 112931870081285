export const devicesDataGrid = [
    { 
        field: 'id',
        headerName: '',
        width: 90,
    },
    {
        field: 'deviceId',
        headerName: 'Device ID',
        width: 90,
    },
];

export const clipsDataGrid = [
    {
        field: 'timestamp',
        headerName: 'Passing Time',
        width: 120,
    },
    {
        field: 'description',
        headerName: 'Description',
        width: 200,
    },
]

export const poleDataGrid = [
    {
        field: 'pole_id',
        headerName: 'Pole Serial',
        minWidth: 150,
    },
    {
        field: 'recording_metadata',
        headerName: 'Description',
        minWidth: 150,
    },
    {
        field: 't_grouping',
        headerName: 'Grouping factor',
        minWidth: 150,
    },
    {
        field: 't_before',
        headerName: 'Crop before',
        minWidth: 150,
    },
    {
        field: 't_after',
        headerName: 'Crop after',
        minWidth: 150,
    },
    {
        field: 't_duration_min',
        headerName: 'Min duration',
        minWidth: 150,
    },
    {
        field: 't_duration_max',
        headerName: 'Max duration',
        minWidth: 150,
    },
    {
        field: 'pole_location_latitude',
        headerName: 'Latitude',
        minWidth: 150,
    },
    {
        field: 'pole_location_longitude',
        headerName: 'Longitude',
        minWidth: 150,
    },
];