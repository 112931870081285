import 'leaflet/dist/leaflet.css';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { GridColDef, DataGrid, GridCellParams } from '@mui/x-data-grid';
import { Button, Chip, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { LatLngTuple, Icon } from 'leaflet';
import { DeleteForeverOutlined, EditOutlined } from '@mui/icons-material';
import FormEventAddEditPole from './FormEventAddEditPole';
import { poleDataGrid } from './helpers/dataGridDefinitions';
import { EventPoleLinkCreate } from '../../types';
import { useGetEventQuery, useRemovePoleFromEventMutation, useStartEventMutation, useEndEventMutation } from '../../features/api/eventSlice';
import VideoOverview from '../videos/VideoOverview';
import { utcDateToLocaleString } from './helpers';

interface EventDetailProps {
    overview: boolean,
}
const EventDetail = ({ overview }: EventDetailProps) => {
    const { id: eventId } = useParams();

    const [editPoleData, setEditPoleData] = useState<[number, EventPoleLinkCreate] | null>(null);

    const { data: event, isLoading, isSuccess, isError } = useGetEventQuery({ eventId: Number(eventId), personal: !overview });
    const [removePole, response] = useRemovePoleFromEventMutation();
    const [startEventRequest] = useStartEventMutation();
    const [endEventRequest] = useEndEventMutation();

    const polesGridColumns: GridColDef[] = [
        ...poleDataGrid,
        ...(overview ? [{
            field: 'actions',
            headerName: '',
            minWidth: 150,
            renderCell: ({ row }: GridCellParams) => (
                <div>
                    <Button onClick={(_) => {
                        const { id, pole, ...rest } = row;
                        setEditPoleData([id, rest]);
                    }}>
                        <EditOutlined />
                    </Button> 
                    <Button onClick={(_) => removePole({ event_id: Number(eventId), pole_id: row.pole_id})}><DeleteForeverOutlined /></Button> 
                </div>
            )
        }] : [])
    ];

    if (isLoading) {
        return (<CircularProgress />);
    } else if (isError) {
        return null;
    } else if (isSuccess) {
        const mapCenter = event.poles.length > 0
            ? [event.poles[0].pole_location_latitude, event.poles[0].pole_location_longitude]
            : [51.013284, 3.709478]; // AA-tower

        return (
            <Grid container spacing={2}>
                <Grid item xs={9} lg={9}>
                    <Typography variant='h4'>{event.title}</Typography>
                    <Typography>{event.description}</Typography>
                </Grid>
                <Grid item xs={2} lg={3}>
                    { overview ?  
                        <>
                            <Button onClick={() => startEventRequest(event.id)} fullWidth variant='outlined' disabled={Boolean(event.start_time)} sx={{ margin: .5 }}>
                                {Boolean(event.start_time) ? `Started at ${utcDateToLocaleString(event.start_time)}`: 'Start Event'}
                            </Button>
                            <Button onClick={() => endEventRequest(event.id)} fullWidth variant="outlined" disabled={!Boolean(event.start_time) || Boolean(event.end_time)} sx={{ margin: .5 }}>
                                {Boolean(event.end_time) ? `Ended on ${utcDateToLocaleString(event.end_time)}`: 'End Event'}
                            </Button>
                        </>
                    : 
                        <>
                            <Chip color='success' label={Boolean(event.start_time) ? `Started at ${utcDateToLocaleString(event.start_time)}`: 'No start time available (yet)'}/>
                            <Chip sx={{marginTop: '3px'}} color='warning' label={Boolean(event.end_time) ? `Ended on ${utcDateToLocaleString(event.end_time)}`: 'No end time available (yet)'}/>
                        </>
                    }
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Divider />
                </Grid>
                <VideoOverview 
                    eventId={event.id}
                    devices={event.devices}
                    poles={event.poles}
                    show={Boolean(!isLoading && event)}
                />
                <Grid item xs={12} lg={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography variant='h4'>Strada poles</Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Box sx={{ height: 400, paddingBottom: 1 }}>
                        <DataGrid 
                            rows={event.poles}
                            columns={polesGridColumns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <MapContainer style={{height: '100%'}} center={mapCenter as LatLngTuple} zoom={13} scrollWheelZoom>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {event.poles.map((pole, i) => (
                            <Marker key={i} position={[pole.pole_location_latitude, pole.pole_location_longitude]} icon={new Icon({ iconUrl: markerIconPng})}>
                                <Popup>
                                    {pole.pole_id}
                                </Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                </Grid>
                <Grid item>
                {overview && 
                    <FormEventAddEditPole 
                        eventId={Number(eventId)}
                        linkedPoles={event.poles.map(pole => pole.pole_id)} 
                        {...(editPoleData !== null) && { 
                            editFormState: editPoleData,
                            clearCallback: () => setEditPoleData(null),
                        }}
                    />
                }
                </Grid>
            </Grid>
        )
    }

    return null;
}

export default EventDetail;