import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { User } from "../../types"

interface UserDisplayProps{
    user: User|null
}

export const UserDisplayComponent = (props : UserDisplayProps)=>{
    return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="/static/images/user.jpg"
          alt="userpic"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.user!.email}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You're an {props.user!.highest_role}!
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You have <Link to={'/settings'}>{props.user!.sensors.length} sensors</Link> linked.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    )
}