import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Grid, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { EventMinimal, EventType } from '../../types';
import { selectImageForEventType, utcDateToLocaleString } from './helpers';
import { useGetEventsQuery } from '../../features/api/eventSlice';

interface EventExcerptProps {
    event: EventMinimal,
    personalized: boolean,
}

const EventOverviewContainer = styled('div')(() => ({
}));

const StyledGrid = styled(Grid)(() => ({
  marginTop: '10px',
}));

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
    color: 'inherit',
}));

const EventExcerpt = ({ event, personalized }: EventExcerptProps) => {
    return (
      <Grid item key={event.id} lg={3}>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            sx={{ height: 140 }}
            image={selectImageForEventType(event.type)}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {event.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {event.start_time ? utcDateToLocaleString(event.start_time) : 'Not started yet'}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">
              <StyledLink to={personalized ?`/events/me/${event.id}`:`/events/${event.id}`}>
                View Event
              </StyledLink>
            </Button>
          </CardActions>
        </Card>
      </Grid>
    )
}

interface EventOverviewProps {
  myEvents: boolean
}

const EventOverview = ({ myEvents } : EventOverviewProps) => {
    const [filterState, setFilterState] = useState<string[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<EventMinimal[]>([]);
    const {
        data: events,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetEventsQuery(myEvents);

    useEffect(() => {
      if (events) {
        const filtered = filterState.length == 0 ? events : events.filter(x => filterState.includes(x.type));
        setFilteredEvents(filtered);
      }
    }, [filterState, events]);

    const handleFilterState = (event: React.MouseEvent<HTMLElement>, newFilters: string[]) => {
      setFilterState(newFilters);
    }

    let content;
    if (isLoading) {
        return (<CircularProgress />)
    } else if (isSuccess) {
        content = filteredEvents.map(event => <EventExcerpt personalized={myEvents} key={event.id} event={event} />);
    }

    return (
      <EventOverviewContainer>
        <ToggleButtonGroup
          value={filterState}
          onChange={handleFilterState}
        >
          {Object.values(EventType).map((type, index) => (
            <ToggleButton key={index} value={type} aria-label={type}>
              {type}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <StyledGrid container spacing={2}>
          {content}    
        </StyledGrid>
      </EventOverviewContainer>
    );
}

export default EventOverview;