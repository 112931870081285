import React, { useCallback, useEffect, useState } from 'react';

import { useGoogleReCaptcha} from 'react-google-recaptcha-v3';

import { Avatar, Box, Button, Container, createTheme, CssBaseline, FormControl, Input, InputAdornment, InputLabel, ThemeProvider, Typography } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { validateEmail, validatePassword } from '../helpers/userValidators';
import { useNavigate } from 'react-router-dom';
import { isFetchBaseQueryError } from '../helpers/apiHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { authSlice } from '../features/authSlice';
import { SensorErrorMessage } from '../types';
import { useRegisterMutation, useGetMeQuery } from '../features/api/authSlice';

const theme = createTheme();

const Register = () => {
    const [registerUser, { isLoading, isError, error, isSuccess }] = useRegisterMutation()
    const atSet = useSelector((state: RootState) => state.authState.access_token !== '');
    const { data: dt } = useGetMeQuery(null, { skip: (!isSuccess || !atSet) });
    const dispatch = useDispatch();

    let navigate = useNavigate();

    let [state, setState] = useState({
        "email": "",
        "password": "",
        "repeatPassword":""
    });

    let [message, setMessage] = useState("");
    let [messageVisibility, setMessageVisibility] = useState("none");

    let [hidePassword, setHidePassword] = useState(true);
    let [hideRepeatPassword, setHideRepeatPassword] = useState(true);

    const {executeRecaptcha} = useGoogleReCaptcha();

    const submitRegistration = useCallback(async () => {
        let stateOK = validateState();
        if(stateOK){
            displayMessage("Clicked on submit.")

            if (!executeRecaptcha) {
                console.log('Recaptcha has not been loaded');
                return;
            }
            const token = await executeRecaptcha('registration');
            registerUser(state);
        }
    }, [executeRecaptcha, state]);

    useEffect(()=>{
        if(error && isFetchBaseQueryError(error)){
            console.log("we have an error")
            console.log(error)
            if(error.data)
                displayMessage((error.data as SensorErrorMessage).message)
        }
    }, [error]);
    
    useEffect(()=>{
        if (dt) {
            dispatch(authSlice.actions.setUser(dt))
            navigate('/');
        }
    }, [dt])

    const handleChange = (evt:any) => {
        setState({
            ...state,
            [evt.target.name]: evt.target.value
        });
      };

    
    const showPassword = () => {
        setHidePassword(!hidePassword);
    }

    const showrepeatPassword = () => {
        setHideRepeatPassword(!hideRepeatPassword);
    }

    const validateState = () => {
        var errors = [];
        var hasErrors = false;
        if(!validateEmail(state.email)){
            hasErrors = true;
            errors.push("Email address is not correctly formatted");
        }
        var passwordValErrors = validatePassword(state.password);
        if(passwordValErrors.length>0){
            hasErrors = true;
            errors.push(passwordValErrors.toString());
        }
        if(!(state.repeatPassword===state.password)){
            hasErrors = true;
            errors.push("Password and confirm password must match");
        }

        console.log(errors);
        console.log(hasErrors);

        if(hasErrors)
            displayMessage(errors.toString());
        
        return !hasErrors;
    }

    const displayMessage = async (text: string) => {
        setMessage(text);
        setMessageVisibility("block");
    }

    const isValid = () => {
        return true;
    }

    return (
            <div>
                <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className="message" style={{display:messageVisibility}}>
                        <span className="closebtn" onClick={()=>{setMessageVisibility('none')}}>&times;</span>
                        {message}
                    </div> 
                    <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <HowToRegIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Register to join the STRADA family!
                        </Typography>
                        <Box component="form" onSubmit={submitRegistration} noValidate sx={{ mt: 1 }}>
                            <FormControl required fullWidth margin="normal">
                                <InputLabel htmlFor="email">
                                    e-mail
                                </InputLabel>
                                <Input
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    value={state.email}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl required fullWidth margin="normal">
                                <InputLabel htmlFor="password">
                                    password
                                </InputLabel>
                                <Input
                                    name="password"
                                    autoComplete="password"
                                    onChange={handleChange}
                                    type={hidePassword ? "password" : "input"}
                                    endAdornment={
                                    hidePassword ? (
                                        <InputAdornment position="end">
                                        <VisibilityOffIcon 
                                            fontSize="inherit"
                                            onClick={showPassword}
                                        />
                                        </InputAdornment>
                                    ) : (
                                        <InputAdornment position="end">
                                        <VisibilityIcon
                                            fontSize="inherit"
                                            onClick={showPassword}
                                        />
                                        </InputAdornment>
                                    )
                                    }
                                />
                            </FormControl>

                            <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="repeatPassword">
                                Confirm password
                            </InputLabel>
                            <Input
                                name="repeatPassword"
                                autoComplete="repeatPassword"
                                onClick={showrepeatPassword}
                                onChange={handleChange}
                                type={hideRepeatPassword ? "password" : "input"}
                                endAdornment={
                                hideRepeatPassword ? (
                                    <InputAdornment position="end">
                                    <VisibilityOffIcon
                                        fontSize="inherit"
                                        onClick={showrepeatPassword}
                                    />
                                    </InputAdornment>
                                ) : (
                                    <InputAdornment position="end">
                                    <VisibilityIcon
                                        fontSize="inherit"
                                        onClick={showrepeatPassword}
                                    />
                                    </InputAdornment>
                                )
                                }
                            />
                            </FormControl>
                            <Button
                                disabled={!isValid()}
                                disableRipple
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={submitRegistration}
                            >
                            Join
                            </Button>
                        </Box>
                    </Box>
                </Container>
                </ThemeProvider>
            </div>
    );
}

export default Register;