import { apiSlice } from "./apiSlice";
import { EventMinimal, Pole, EventPoleLinkCreate, EventPoleLinkDelete, Event, ClipInfo } from "../../types";

const eventSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEvents: builder.query<EventMinimal[], boolean>({
            query: (myEvents: boolean) => myEvents ? '/users/me/events':'/events',
            providesTags: ['MyEventTag']
        }),
        getEvent: builder.query<Event, { eventId: number, personal: boolean }>({
            query: params => params.personal ? `/users/me/event/${params.eventId}` : `/events/${params.eventId}`,
            providesTags: ['EventTag', 'MyEventTag'],
        }),
        getClipgenStatusEndpoint: builder.query<string, { eventId: number, deviceId: string }>({
            query: ({ eventId, deviceId }) => `/events/${eventId}/generate?sensor_id=${deviceId}`,
        }),
        getEventClipsForDevice: builder.query<ClipInfo[], { eventId: number, deviceId: string}>({
            query: params => `/events/${params.eventId}/clips/${params.deviceId}`,
            transformResponse: (response: ClipInfo[], meta, arg) => {
                return response.sort((a, b) => new Date(Date.parse(a.start_datetime)).getTime() - new Date(Date.parse(b.start_datetime)).getTime());
            },
            providesTags: ['ClipsTag'],
        }),
        getPoles: builder.query<Pole[], void>({
            query: () => '/users/me/poles',
        }),
        startEvent: builder.mutation<void, number>({
            query: (eventId) => ({
                url: `/events/${eventId}/start`,
                method: 'POST',
            }),
            invalidatesTags: ['EventTag'],
        }),
        endEvent: builder.mutation<void, number>({
            query: (eventId) => ({
                url: `/events/${eventId}/end`,
                method: 'POST',
            }),
            invalidatesTags: ['EventTag'],
        }),
        addPoleToEvent: builder.mutation<{}, EventPoleLinkCreate>({
            query: eventPole => ({
                url: `/events/${eventPole.event_id}/pole`,
                method: 'POST',
                body: eventPole,
                headers: {
                'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['EventTag'],
        }),
        updatePoleFromEvent: builder.mutation<{}, EventPoleLinkCreate>({
            query: payload => ({
                url: `/events/${payload.event_id}/poles/${payload.pole_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['EventTag'],
        }),
        removePoleFromEvent: builder.mutation<{}, EventPoleLinkDelete>({
            query: eventPoleMin => ({
                url: `/events/${eventPoleMin.event_id}/poles/${eventPoleMin.pole_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['EventTag'],
        }),
    }),
});

export const {
  useGetEventsQuery,
  useGetEventQuery,
  useGetClipgenStatusEndpointQuery,
  useGetPolesQuery,
  useGetEventClipsForDeviceQuery,
  useStartEventMutation,
  useEndEventMutation,
  useAddPoleToEventMutation,
  useUpdatePoleFromEventMutation,
  useRemovePoleFromEventMutation,
} = eventSlice;