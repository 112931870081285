import React from 'react';
import { Box, IconButton, Avatar, Menu, MenuItem, Typography, Tooltip, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const settings = [
    {
        name: 'My profile',
        link: '/users/me',
    },
    {
        name: 'Sensors',
        link: '/settings',
    },
    {
        name: 'Log out',
        link: '/logout',
    }
];

interface SettingsIconMenuProps {
    handleOpenUserMenu: (e: React.MouseEvent<HTMLElement>) => void,
    handleCloseUserMenu: () => void,
    anchorElUser: HTMLElement | null,
}

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
    color: 'inherit',
}));

const SettingsIconMenu = ({ handleOpenUserMenu, handleCloseUserMenu, anchorElUser }: SettingsIconMenuProps) => {
    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting) => (
                    <MenuItem key={setting.name}>
                        <StyledLink to={setting.link}>
                            <Typography textAlign="center">{setting.name}</Typography>
                        </StyledLink>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default SettingsIconMenu;